import React from "react";
// import List from "./list";

const Home = () => {
  return (
    <div className="page">
      <header className="homehead">
        <div className="typewriter">
          <h1>welcome to my home page</h1>
        </div>
      </header>
      <main>
        <div className="home-container">
          <img
            src="./face.jpg"
            className="profileImg"
            width="200px"
            alt="Hannah"
          />
          <div className="box">
            <p>
              Hello! <br></br>
              I'm Hannah, a junior studying Computer Science and Software
              Engineering at the University of Washington Bothell.
              <br></br>
              <br></br>
              Some things I enjoy are:
              <ul>
                <li> Sweets (cake, ice cream, cookies, etc) </li>
                <li> Shows (currently watching the Walking Dead)</li>
                <li> Sleep! </li>
              </ul>
            </p>
          </div>
        </div>
        {/* <List /> */}
      </main>
    </div>
  );
};

export default Home;
