//import thingsToRead from "./components/ThingstoRead";
import React from "react";
import "./App.css";
import Navbar from "./components/navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home";
import ThingsToRead from "./components/thingsToRead";
import Navigation from "./components/howToNavigate";
import Attention from "./components/attention";

function App() {
  return (
    <Router>
       <Navbar />
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/thingsToRead" element={<ThingsToRead />} />
          <Route path="/howToNavigate" element={<Navigation />} />
          <Route path="/attention" element={<Attention />} />
        </Routes>

    </Router>
  );
}

export default App;
