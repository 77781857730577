import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="nav">
      <li>
        <Link to="/">home</Link>
      </li>
      <li>
        <Link to="/thingsToRead">things to read</Link>
      </li>
      <li>
        <Link to="/howToNavigate">how to navigate</Link>
      </li>
      <li>
        <Link to="/attention"> attention</Link>
      </li>
    </div>
  );
};
export default Navbar;
