import React from "react";
// add a "Things to Read" page to your site that will contain links to
// information you are interested in on this second page, have at least
// 10 links to websites (outside of your own)

const ThingsToRead = () => {
  return (
    <div className="page">
      <h1> things to read</h1>

      <main>
        {/* <div className="home-container"> */}
        <div>
          <div className="box">
            <h2>cool user interfaces:</h2>
            <p>
              The first thing that comes to mind are the various sites I've
              saved. To start, I just happen to stumble on this page on
              Pinterest and really liked the UI.
              <li>
                <a href="https://girafe.co/?ref=lapaninja"> girafe </a>
              </li>
              <div className="web-img">
                <img
                  src="/images/girafe.png"
                  alt="girafe"
                  class="responsive"
                  width="400px"
                />
              </div>
            </p>

            <p>
              Another UI I really liked was this one. It also has great dynamic
              graphics.
              <li>
                <a href="https://www.dabeeo.com/"> dabeeo </a>
              </li>
              <img
                src="/images/dabeeo.png"
                alt="dabeeo"
                class="responsive"
                width="400px"
              />
            </p>

            <p>
              I believe in the same class, I found this cool diagram.
              <li>
                <a href="https://anatomyof.ai/img/ai-anatomy-map.pdf">
                  Anatomy Of AI
                </a>
              </li>
              <img
                src="/images/ai.png"
                alt="anatomyofai"
                class="responsive"
                width="400px"
              />
            </p>
          </div>
          <div className="box">
            <h2>cool tools:</h2>

            <p>
              This is a great site for low fidelity wireframing! Although it
              isn't free unfortunately, even for students, but it has great
              outlines.
              <li>
                <a href="https://balsamiq.com/"> balsamiq</a>
              </li>
              <img
                src="/images/balsamiq.png"
                alt="balsamiq"
                class="responsive"
                width="400px"
              />
            </p>

            <p>
              This site I recently found while working on graphics for the UWB
              ACM club. It's a great resource for free images and vectors. It
              even allows for customizable colors!
              <li>
                <a href="https://storyset.com/"> storyset </a>{" "}
              </li>
              <img
                src="/images/storyset.png"
                alt="storyset"
                class="responsive"
                width="400px"
              />
            </p>
          </div>
          <div className="box">
            <h2>cool shows:</h2>
            <p>
              Some other things I'm interested in are shows! Here are some links
              about my favorite childhood show Adventure Time! <br></br>
              <li>
                <a href="https://en.wikipedia.org/wiki/Adventure_Time">
                  Wikipedia of Adventure Time
                </a>
              </li>
              <li>
                <a href="https://www.imdb.com/title/tt1305826/">
                  IMDB of Adventure Time
                </a>
              </li>
              <li>
                <a href="https://www.giantbomb.com/adventure-time/3025-2327/">
                  A Database of Adventure Time Characters
                </a>
              </li>
              <img
                src="/images/adventuretime.png"
                alt="adventuretime"
                class="responsive"
                width="400px"
              />
            </p>

            <p>
              Now for my recently favorite show, the Walking Dead, here are some
              informational links as well! <br></br>
              <li>
                <a href="https://en.wikipedia.org/wiki/The_Walking_Dead_(TV_series)">
                  Wikipedia of The Walking Dead
                </a>
              </li>
              <li>
                <a href="https://www.imdb.com/title/tt1520211/">
                  IMDB of The Walking Dead
                </a>
              </li>
              <img
                src="/images/walkingdead.png"
                alt="walkingdead"
                class="responsive"
                width="400px"
              />
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ThingsToRead;
