import React from "react";
const Navigation = () => {
  return (
    <div className="page">
      <h1>how to navigate with keyboard shortcuts</h1>
      <div className="box">
        <p>
          {" "}
          First, click the tab button on your keyboard to enable the element
          selection. <br></br>
          <img
            src="../tab.png"
            alt="arrow pointing to tab on keyboard"
            class="responsive"
          />
        </p>
      </div>
      <br></br>
      <div className="box">
        <p>
          {" "}
          The tab provides navigation going down the links and to get to the
          links going up, press the shift key while manuvering with the tab
          button. <br></br>
          <img
            src="../tab-shift.png"
            alt="arrow pointing to tab and shift on keyboard"
            class="responsive"
          />
        </p>
      </div>
    </div>
  );
};
export default Navigation;
